import axios from 'axios'
import router from './../../../router'


export const getUser = ({ commit }) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('pwewep34KKOE34epwep68_9dwjDKLL')}`;
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/user-logged")
            .then((response) => {
                if (response.data) {
                    commit('SET_USER', response.data)
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch((errors) => {
                localStorage.removeItem("pwewep34KKOE34epwep68_9dwjDKLL");
                router.push("/login");
                if (errors.response.status == "500" || errors.response.status == "429") {
                    router.push("/server-error");
                }
                reject(errors.response.status);
            })
    })
}

export const getToken = ({ commit }, user) => {
    return new Promise((resolve, reject) => {
        axios.get('/sanctum/csrf-cookie').then(() => {
            axios
                .post("/api/v1/login", user)
                .then((response) => {
                    localStorage.setItem("pwewep34KKOE34epwep68_9dwjDKLL", response.data.token);
                    if (response.data.role == 99) {
                        router.push("/app/users");
                    } else {
                        router.push("/app/dashboard");
                    }
                    resolve(false);
                })
                .catch((errors) => {
                    reject(errors.response.data.errors);
                });
        }).catch(e => {

        });
    })
}

export const logout = () => {
    return new Promise((resolve, reject) => {
        axios
            .post("/api/v1/logout")
            .then((response) => {
                if (response.data) {
                    localStorage.removeItem("pwewep34KKOE34epwep68_9dwjDKLL"); //remove user token when logout
                    router.push("/");
                    setTimeout(() => {
                        location.reload();
                    }, 0); // push user to login page
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch((errors) => {
                router.push("/");
                if (errors.response.status == "500" || errors.response.status == "429") {
                    router.push("/server-error");
                }
                reject(errors.response.status);
            })
    })
}