import axios from 'axios'
const ROUTE = 'pembelajaran';
const NAMA = 'nama_pembelajaran';

export const get = ({ commit }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/" + ROUTE)
            .then((response) => {
                if (response.data) {
                    commit('SET_ITEMS', response.data)
                    commit("SET_OVERLAY", false, { root: true });
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {

            });
    })
}

export const store = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    let nama = payload[NAMA];
    return new Promise((resolve, reject) => {
        axios.post("/api/v1/" + ROUTE, payload).then((response) => {
            dispatch("get").then(() => {
                let notif = {
                    msg: nama + " Berhasil ditambahkan~",
                    type: "create"
                }
                commit("SET_NOTIF", notif, { root: true });
                resolve("ok")
            });
        }).catch((e) => {
            reject(e.response.data.errors);
            commit("SET_OVERLAY", false, { root: true });
        });
    })
}

export const update = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    let nama = payload[NAMA];
    return new Promise((resolve, reject) => {
        axios.put("/api/v1/" + ROUTE + "/" + payload.id, payload).then((response) => {
            dispatch("get").then(() => {
                let notif = {
                    msg: nama + " Berhasil diupdate~",
                    type: "update"
                }
                commit("SET_NOTIF", notif, { root: true });
                resolve("ok")
            });
        }).catch((e) => {
            reject(e.response.data.errors);
            commit("SET_OVERLAY", false, { root: true });
        });
    })
}

export const destroy = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    let nama = payload[NAMA];
    return new Promise((resolve, reject) => {
        axios.delete("/api/v1/" + ROUTE + "/" + payload.id).then((response) => {
            dispatch("get").then(() => {
                let notif = {
                    msg: nama + " Berhasil dihapus~",
                    type: "delete"
                }
                commit("SET_NOTIF", notif, { root: true });
                resolve("ok")
            });
        }).catch((e) => {
            if (e.response.data.code == 1451) {
                let existNotif = "*Data pernah digunakan untuk input data lainnya.";
                commit("SET_SQL", existNotif, { root: true });
            }
            commit("SET_OVERLAY", false, { root: true });
        });
    })
}