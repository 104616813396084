export const setNotif = ({ commit }, notif) => {
    commit('SET_NOTIF', notif);
}

export const getNotif = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        axios.get("/api/get-notifs/" + payload).then((res) => {
            commit("SET_PESANS", res.data);
        });
    });
}

export const getDashboardGeneral = ({ commit }) => {
    return new Promise((resolve, reject) => {
        axios.get("/api/v1/dashboard-general").then((res) => {
            commit("SET_DASHBOARD_GENERAL", res.data);
            resolve(true);
        });
    });
}