import axios from 'axios'
const API_PATH = 'general';
const NAMA = 'nama';
export const get = ({ state, commit, dispatch }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/" + API_PATH)
            .then((response) => {
                commit('SET_ITEMS', response.data);
                commit("atm/SET_DATE", response.data.date, { root: true });
                commit("SET_OVERLAY", false, { root: true });
                let existInterval = state.dateTimeInterval;
                if (existInterval) {
                    resolve('ok');
                    return;
                } else {
                    state.dateTimeInterval = setInterval(() => {
                        dispatch('updateTime');
                    }, 1000);
                }
            })
            .catch((errors) => {
                alert('something error');
                reject(errors)
            });
    })
}

export const getRiwayatAbsen = ({ state, commit }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/riwayat-absen-tutor")
            .then((response) => {
                commit('SET_RIWAYAT_ABSEN', response.data);
                commit("SET_OVERLAY", false, { root: true });
                resolve(true)
            })
            .catch((errors) => {
                reject(errors)
            });
    })
}

export const getRiwayatAbsenToday = ({ state, commit }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/riwayat-absen-tutor-today")
            .then((response) => {
                commit('SET_RIWAYAT_ABSEN', response.data);
                commit("SET_OVERLAY", false, { root: true });
                resolve(true)
            })
            .catch((errors) => {
                reject(errors)
            });
    })
}

export const getRiwayatAjukanPresensi = ({ commit }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/riwayat-ajukan-presensi")
            .then((response) => {
                if (response.data) {
                    commit('SET_RIWAYAT_AJUKAN_PRESENSI', response.data)
                    commit("SET_OVERLAY", false, { root: true });
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {
                alert('error');
                commit("SET_OVERLAY", false, { root: true });
            });
    })
}

export const getRiwayatAjukanPresensiToday = ({ commit }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/riwayat-ajukan-presensi-today")
            .then((response) => {
                if (response.data) {
                    commit('SET_RIWAYAT_AJUKAN_PRESENSI', response.data)
                    commit("SET_OVERLAY", false, { root: true });
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {
                alert('error');
                commit("SET_OVERLAY", false, { root: true });
            });
    })
}

export const deleteAjukanPresensi = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .delete("/api/v1/ajukan-presensi/" + payload.id)
            .then(() => {
                dispatch("getRiwayatAjukanPresensiToday").then(() => {
                    resolve(true);
                })
            })
            .catch((e) => {
                console.log(e);
                alert('error');
                commit("SET_OVERLAY", false, { root: true });
            });
    })
}


export const serverTime = ({ state, commit, dispatch }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios.get("/api/v1/server-time").then((response) => {
            commit("SET_OVERLAY", false, { root: true });
            commit("SET_SERVER_TIME", response.data.server_time);
            commit("SET_DATE", response.data.date);
            let existInterval = state.dateTimeInterval;
            if (existInterval) {
                resolve('ok');
                return;
            } else {
                state.dateTimeInterval = setInterval(() => {
                    dispatch('updateTime');
                }, 1000);
            }
        }).catch((err) => {
            commit("SET_OVERLAY", false, { root: true });
            alert(err);
        });
    })
}

function zeroPadding(num, digit) {
    var zero = "";
    for (var i = 0; i < digit; i++) {
        zero += "0";
    }
    return (zero + num).slice(-digit);
}

const week = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"];
const month = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
];

export const updateTime = ({ state, commit }) => {
    let cd = new Date(state.items.server_time);
    let time =
        zeroPadding(cd.getHours(), 2) +
        ":" +
        zeroPadding(cd.getMinutes(), 2) +
        ":" +
        zeroPadding(cd.getSeconds(), 2);
    commit("SET_TIME", time);
    let date_indo =
        week[cd.getDay()] +
        ", " +
        zeroPadding(cd.getDate(), 2) +
        " " +
        month[cd.getMonth()] +
        " " +
        zeroPadding(cd.getFullYear(), 4);
    commit("SET_DATE_INDO", date_indo);
    let temp = cd.setSeconds(cd.getSeconds() + 1);
    commit("SET_SERVER_TIME", temp);
}