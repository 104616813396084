import axios from 'axios'
const API_PATH = "absen-tutor-manager";
export const get = ({ commit }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/" + API_PATH + "/" + payload.date)
            .then((response) => {
                if (response.data) {
                    commit('SET_ITEMS', response.data)
                    commit("SET_OVERLAY", false, { root: true });
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {

            });
    })
}