import axios from 'axios'
const API_PATH = "tutor";
const NAMA = "nama_profile";
export const get = ({ commit }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/" + API_PATH)
            .then((response) => {
                if (response.data) {
                    commit('SET_ITEMS', response.data)
                    commit("SET_OVERLAY", false, { root: true });
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {

            });
    })
}

export const getUnAktif = ({ commit }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/un-aktif-tutor")
            .then((response) => {
                if (response.data) {
                    commit('SET_ITEMS_UN_AKTIF', response.data)
                    commit("SET_OVERLAY", false, { root: true });
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {

            });
    })
}


export const destroy = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    let nama = payload.user.nama_profile + " - " + payload.user.username;
    return new Promise((resolve, reject) => {
        axios.delete("/api/v1/" + API_PATH + "/" + payload.id).then((response) => {
            dispatch("get").then(() => {
                let notif = {
                    msg: nama + " Berhasil dihapus~",
                    type: "delete"
                }
                commit("SET_NOTIF", notif, { root: true });
                resolve("ok")
            });
        }).catch((e) => {
            if (e.response.data.code == 1451) {
                let existNotif = "*Data pernah digunakan untuk input data lainnya.";
                commit("SET_SQL", existNotif, { root: true });
            }
            commit("SET_OVERLAY", false, { root: true });
        });
    })
}

export const resetPassword = ({ commit }, payload) => {
    let nama = payload.nama_profile + " - " + payload.username;
    commit("SET_OVERLAY", true, { root: true });
    axios
        .put("/api/v1/tutor/reset-password/" + payload.id)
        .then((response) => {
            let notif = {
                msg: "Data user dari " + nama + " berhasil direset~",
                type: "update",
            };
            commit("SET_NOTIF", notif, { root: true });
        })
        .catch((e) => {

        })
        .finally(() => {
            commit("SET_OVERLAY", false, { root: true });
        })
}

export const hapusTerpilih = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        let ids = payload.map(x => x.id);
        axios.post("/api/v1/hapus-terpilih/" + API_PATH, { ids: ids }).then((response) => {
            dispatch("get").then(() => {
                let notif = {
                    msg: payload.length + " item, Berhasil dihapus~",
                    type: "delete"
                }
                commit("SET_NOTIF", notif, { root: true });
                resolve("ok")
            });
        }).catch((e) => {
            if (e.response.data.code == 1451) {
                let existNotif = "*Ada beberapa data yang pernah digunakan untuk input data lainnya.";
                commit("SET_SQL", existNotif, { root: true });
            }
            commit("SET_OVERLAY", false, { root: true });
        });
    })
}