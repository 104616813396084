export default {
    items: {
        server_time: null,
        time: 'tes',
        date: null,
        date_indo: null,
        waktu_absen: {
            waktu_masuk_jam: null,
            waktu_masuk_menit: null,
            waktu_pulang_jam: null,
            waktu_pulang_menit: null
        },
        riwayat_absen_tutor: [],
        riwayat_ajukan_presensi: [],
        app_info: {
            logs: [],
            build: '',
            config: {
                is_maintenis: false,
                is_dev_mode: false
            }
        }
    },

    dateTimeInterval: null,
}