require('./bootstrap');
window.Vue = require('vue').default;
import Vue from 'vue';
import vuetify from './vuetify';
import VueRouter from 'vue-router';
import router from './router';
import store from "./store/index";
import VueQrcode from '@chenfengyuan/vue-qrcode';

Vue.use(VueRouter);

Vue.component('loading-page', require('./components/LoadingPage.vue').default);

Vue.component('my-app', require('./components/App.vue').default);

Vue.component(VueQrcode.name, VueQrcode);

const app = new Vue({
    el: '#app',
    vuetify,
    router,
    store,
    data: { loading: false },
});

router.beforeEach((to, from, next) => {
    app.loading = true
    next()
})

router.afterEach(() => {
    setTimeout(() => app.loading = false, 0) // timeout for demo purposes
})