export default {
    expire: false,
    dev_mode: true,
    notif: {
        msg: null,
        type: null,
        left: null,
        timeout: 7000
    },
    sql: null,
    overlay: false,
    overlay_bg: null,
    anchor: null,
    sideNav: false,
    pesans: [],
    unread_pesans: [],
    dashboard_general: {
        time_date: null,
        info_singkat: []
    },
}