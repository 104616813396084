export const SET_NOTIF = (state, notif) => {
    state.notif = {
        msg: notif.msg,
        type: notif.type,
        left: notif.left,
        timeout: notif.timeout
    }
}

export const UNSET_NOTIF = (state, notif) => {
    state.notif = {
        msg: null,
        type: null,
        left: false,
        timeout: 7000
    }
}

export const SET_SQL = (state, payload) => {
    state.sql = payload;
}

export const UNSET_SQL = (state) => {
    state.sql = null;
}

export const SET_OVERLAY = (state, overlay) => {
    state.overlay = overlay
}

export const SET_OVERLAY_BG = (state, overlay_bg) => {
    state.overlay_bg = overlay_bg
}

export const SET_ANCHOR = (state, payload) => {
    state.anchor = payload
}

export const SET_PESANS = (state, payload) => {
    state.pesans = payload
}

export const SET_UNREAD_PESANS = (state, payload) => {
    state.unread_pesans = payload
}

export const SET_DASHBOARD_GENERAL = (state, payload) => {
    state.dashboard_general = payload
}

export const SET_CONFIG = (state, payload) => {
    state.config = payload
}

export const SET_APP_LOGS = (state, payload) => {
    state.app_logs = payload
}

export const SET_EXPIRE = (state, payload) => {
    state.expire = payload
}