export const SET_YEARS = (state, payload) => {
    state.years = payload;
}

export const SET_YEAR = (state, payload) => {
    state.year = payload;
}

export const SET_ITEMS = (state, payload) => {
    state.items = payload;
}

export const SET_ITEM_TANGGAL_CONFIGS = (state, payload) => {
    let objIndex = state.items.findIndex((obj => obj.id == payload.id));
    state.items[objIndex].configs = payload.configs;
}

export const SET_PERIODE_AKTIF = (state, payload) => {
    state.periode_aktif = payload;
}