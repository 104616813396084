import axios from 'axios'
const API_PATH = "tutor-same-as-oncall";
const NAMA = "nama_profile";
export const get = ({ commit }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .post("/api/v1/" + API_PATH, payload)
            .then((response) => {
                if (response.data) {
                    commit('SET_TUTORS_SAME_AS_ONCALL', response.data)
                    commit("SET_OVERLAY", false, { root: true });
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {

            });
    })
}