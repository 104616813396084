export const SET_ITEMS = (state, payload) => {
    state.items = payload;
}

export const SET_SERVER_TIME = (state, payload) => {
    state.items.server_time = payload;
}

export const SET_TIME = (state, payload) => {
    state.items.time = payload;
}

export const SET_DATE = (state, payload) => {
    state.items.date = payload;
}

export const SET_DATE_INDO = (state, payload) => {
    state.items.date_indo = payload;
}

export const SET_RIWAYAT_ABSEN = (state, payload) => {
    state.items.riwayat_absen_tutor = payload;
}

export const SET_RIWAYAT_AJUKAN_PRESENSI = (state, payload) => {
    state.items.riwayat_ajukan_presensi = payload;
}

export const SET_CONFIG = (state, payload) => {
    state.items.app_info.config = payload;
}