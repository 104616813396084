export const SET_ITEMS = (state, payload) => {
    state.items = payload;
}

export const SET_ITEMS_UN_AKTIF = (state, payload) => {
    state.itemsUnAktif = payload;
}

export const SET_RIWAYAT_MENGAJAR = (state, payload) => {
    let objIndex = state.items.findIndex((obj => obj.id == payload.tutor_id));
    state.items[objIndex].riwayat_mengajar = payload.riwayat_mengajar;
}

export const SET_RIWAYAT_MENGAJAR_UN_AKTIF = (state, payload) => {
    let objIndex = state.itemsUnAktif.findIndex((obj => obj.id == payload.tutor_id));
    state.itemsUnAktif[objIndex].riwayat_mengajar = payload.riwayat_mengajar;
}