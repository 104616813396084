import axios from 'axios'
const ROUTE = 'laporan-absensi';

export const get = ({ commit }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/laporan-absensi-by-periode/" + payload.periode_id)
            .then((response) => {
                commit('SET_ITEMS', response.data)
                commit("SET_OVERLAY", false, { root: true });
                resolve(true)
            })
            .catch(() => {
                commit("SET_OVERLAY", false, { root: true });
            });
    })
}