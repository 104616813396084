export const SET_YEARS = (state, payload) => {
    state.years = payload;
}

export const SET_YEAR = (state, payload) => {
    state.year = payload;
}

export const SET_ITEMS = (state, payload) => {
    state.items = payload;
}