import axios from 'axios'
export const get = ({ state, commit, dispatch }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios.get("/api/v1/server-time").then((response) => {
            commit("SET_OVERLAY", false, { root: true });
            commit("SET_TIME_DATE", response.data.time_date);
            let existInterval = state.dateTimeInterval;
            if (existInterval) {
                resolve('ok');
                return;
            } else {
                state.dateTimeInterval = setInterval(() => {
                    dispatch('updateTime');
                }, 1000);
            }
        }).catch((err) => {
            commit("SET_OVERLAY", false, { root: true });
            alert(err);
        });
    })
}

function zeroPadding(num, digit) {
    var zero = "";
    for (var i = 0; i < digit; i++) {
        zero += "0";
    }
    return (zero + num).slice(-digit);
}

const week = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"];
const month = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
];

export const updateTime = ({ state, commit }) => {
    let cd = new Date(state.item.time_date);
    let time =
        zeroPadding(cd.getHours(), 2) +
        ":" +
        zeroPadding(cd.getMinutes(), 2) +
        ":" +
        zeroPadding(cd.getSeconds(), 2);
    commit("SET_TIME", time);
    let date =
        week[cd.getDay()] +
        ", " +
        zeroPadding(cd.getDate(), 2) +
        " " +
        month[cd.getMonth()] +
        " " +
        zeroPadding(cd.getFullYear(), 4);
    commit("SET_DATE", date);
    let temp = cd.setSeconds(cd.getSeconds() + 1);
    commit("SET_TIME_DATE", temp);
}

// export const startTimeDate = ({ commit, dispatch }) => {
//     axios.get("/api/v1/server-time").then((response) => {
//         commit("SET_TIME_DATE", response.data.time_date);
//         setInterval(() => {
//             dispatch('updateTime');
//         }, 1000);
//     });
// }