import axios from 'axios'

export const getUsers = ({ commit }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/user")
            .then((response) => {
                commit('SET_USERS', response.data);
                commit("SET_OVERLAY", false, { root: true });
                resolve('ok');
            })
            .catch((errors) => {
                reject('fail')
            });
    })
}

export const importUsers = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .post("/api/v1/import-user", payload)
            .then((response) => {
                if (response.data) {
                    dispatch("getUsers");
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {

            });
    })
}

export const importPrivileges = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .post("/api/v1/import-privilege", payload)
            .then((response) => {
                if (response.data) {
                    dispatch("getUsers");
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {

            });
    })
}

export const resetPassword = ({ commit }, payload) => {
    let nama = payload.username;
    commit("SET_OVERLAY", true, { root: true });
    axios
        .put("/api/v1/user/reset-password/" + payload.id)
        .then((response) => {
            let notif = {
                msg: "Data user dari " + nama + " berhasil direset~",
                type: "update",
            };
            commit("SET_NOTIF", notif, { root: true });
        })
        .catch((e) => {

        })
        .finally(() => {
            commit("SET_OVERLAY", false, { root: true });
        })
}

export const store = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    let nama = payload.username;
    return new Promise((resolve, reject) => {
        axios.post("/api/v1/user", payload).then(() => {
            dispatch("getUsers").then(() => {
                let notif = {
                    msg: nama + " Berhasil ditambahkan~",
                    type: "create"
                }
                commit("SET_NOTIF", notif, { root: true });
                resolve("ok")
            });
        }).catch((e) => {
            reject(e.response.data.errors);
            commit("SET_OVERLAY", false, { root: true });
        });
    })
}

export const update = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    let nama = payload.username;
    return new Promise((resolve, reject) => {
        axios.put("/api/v1/user/" + payload.id, payload).then(() => {
            dispatch("getUsers").then(() => {
                let notif = {
                    msg: nama + " Berhasil diupdate~",
                    type: "update"
                }
                commit("SET_NOTIF", notif, { root: true });
                resolve("ok")
            });
        }).catch((e) => {
            reject(e.response.data.errors);
            commit("SET_OVERLAY", false, { root: true });
        });
    })
}

export const destroy = ({ commit, dispatch }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    let nama = payload.username;
    return new Promise((resolve, reject) => {
        axios.delete("/api/v1/user/" + payload.id).then(() => {
            dispatch("getUsers").then(() => {
                let notif = {
                    msg: nama + " Berhasil dihapus~",
                    type: "delete"
                }
                commit("SET_NOTIF", notif, { root: true });
                resolve("ok")
            });
        }).catch((e) => {
            reject(e.response.data.errors);
            commit("SET_OVERLAY", false, { root: true });
        });
    })
}