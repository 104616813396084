<template>
  <div class="loading-wrapper">
    <span class="loader"></span>
  </div>
</template>

<script>
export default {};
</script>

<style>
.loading-wrapper {
  align-items: center;
  background: #fff;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #C0CA33;
  box-shadow: 30px 0 0 #C0CA33
;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: #5E35B1;
  box-shadow: 30px 0 0 #5E35B1;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #039BE5
;
  }
  50% {
    box-shadow: 0 0 0 #039BE5
;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #039BE5
;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #00897B;
  }
  50% {
    box-shadow: 0 0 0 #00897B;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #00897B;
    margin-top: 0;
  }
}
</style>