import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)

import '@mdi/font/css/materialdesignicons.css'

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#0470be',
                secondary: '#0470be',
            },
        },
    },
    icons: {
        iconfont: 'mdi'
    },
})